<template>
  <!-- 改了 -->
  <div class="main" :class="show ? 'h5' : ''">
    <div class="htop">
      <mtop id="ts-top" />
    </div>
    <!-- <div class="banner">
			<img src="../assets/sdImages/about/Maskgroup.png" alt="szzc" class="simg" />
		</div> -->
    <div class="banner">
      <div class="banimg">
        <div class="title">关于我们</div>
        <div class="tip">致力于成为场景化AI应用解决方案专家</div>
        <div class="btn-box">
          <div
            class="btn"
            @click="(dialogVisible = true), clickMenu('免费试用')"
          >
            立即咨询
          </div>
        </div>
      </div>
    </div>
    <div class="abg-radio" id="top-btns">
      <div
        class="abg-nav"
        v-for="(item, index) in jqNewsBtns"
        :key="item.id"
        :class="{ active: current2 == index }"
        @click="(current2 = index), getScroll()"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="some-line"></div>
    <div class="main_box" id="scrollGsjs">
      <div class="box1">
        <div class="left_con">
          <div class="imgabout">
            <img src="../assets/sdImages/about/ABOUTUS.png" alt="" />
          </div>
          <div class="title">公司介绍</div>
          <div class="cont">
            合肥善达信息科技有限公司成立于2015年，是一家专注于智能音视频、大数据、生成式大模型领域的高新技术企业，深耕于人工智能技术的系统集成、研发、应用，旨在为用户提供智慧金融、智慧政务、智慧园区和企业数字化领域的场景化AI应用解决方案和服务。
          </div>
          <div class="cont">
            自主研发了智能客服、语音智控、视频汇聚、视频AI算法服务器、私有化大模型等产品，业务领域涵盖金融、科技、运营商、高校、能源、医疗等行业。
          </div>
        </div>
        <div class="video-box img">
          <img
            src="../assets/sdImages/about/Vector@2x.png"
            alt=""
            class="play"
            v-if="!showControls"
          />
          <video
            :controls="showControls"
            src="../assets/introduce.mp4"
            @click="toggleControls"
            poster="../assets/sdImages/about/000.jpg"
          ></video>
        </div>
        <div class="item">
          <div class="img">
            <img src="../assets/sdImages/about/Group1567.png" alt="" />
          </div>
          <div class="cont">
            <div class="title">企业使命</div>
            <div class="txt">用人工智能技术赋能各行各业</div>
          </div>
        </div>
        <div class="item">
          <div class="img">
            <img src="../assets/sdImages/about/Group1568.png" alt="" />
          </div>
          <div class="cont">
            <div class="title">企业愿景</div>
            <div class="txt">成为国家级专精特新企业</div>
          </div>
        </div>
        <div class="item">
          <div class="img">
            <img src="../assets/sdImages/about/Group1569.png" alt="" />
          </div>
          <div class="cont">
            <div class="title">核心价值观</div>
            <div class="txt">专业、高效、协同、共生</div>
          </div>
        </div>
      </div>
    </div>
    <div class="about_box fzlc" id="scrollFzlc">
      <div class="btop">
        <div class="title">发展历程</div>
      </div>
      <div class="box2">
        <div class="out-swiper-container">
          <div
            class="swiper-container swiper-container1"
            id="imgSwiper"
            ref="myimgSwiper"
          >
            <!-- <div class="Gtitle">合作伙伴</div> -->
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="in-slide">
                  <div class="time">2015-2016</div>
                  <div class="line">
                    <div class="line-dy">
                      <div class="line-xy"></div>
                    </div>
                    <div class="x-line"></div>
                  </div>
                  <div class="content">
                    2015年3月成立，同年5月承建科大讯飞智能业务实施运维项目
                  </div>
                  <div class="content">2016年5月，增加注册资本至1000万。</div>
                </div>
                <div class="in-slide">
                  <div class="time">2017-2018</div>
                  <div class="line">
                    <div class="line-dy">
                      <div class="line-xy"></div>
                    </div>
                    <div class="x-line"></div>
                  </div>
                  <div class="content">
                    公司AI业务逐渐扩大，首次取得“双软认证”，多项软著、软件产品
                  </div>
                  <div class="content">
                    进一步推进人工智能业务发展，并首次获得国家高新技术企业认证
                  </div>
                </div>
                <div class="in-slide">
                  <div class="time">2019</div>
                  <div class="line">
                    <div class="line-dy">
                      <div class="line-xy"></div>
                    </div>
                    <div class="x-line"></div>
                  </div>
                  <div class="content">首次通过合肥市大数据企业认证</div>
                  <div class="content">荣获合肥市高新区“雏鹰企业”称号</div>
                </div>
                <div class="in-slide">
                  <div class="time">2020</div>
                  <div class="line">
                    <div class="line-dy">
                      <div class="line-xy"></div>
                    </div>
                    <div class="x-line"></div>
                  </div>
                  <div class="content">
                    安徽省股权托管交易中心科技创新专版培育层挂牌
                  </div>
                  <div class="content">
                    自主研发产品“善达信息语音智能控制系统V1.0”产业化
                  </div>
                  <div class="content">合肥市重点产业链企业</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="in-slide">
                  <div class="time">2021</div>
                  <div class="line">
                    <div class="line-dy">
                      <div class="line-xy"></div>
                    </div>
                    <div class="x-line"></div>
                  </div>
                  <div class="content">合肥市优质小微工业企业</div>
                  <div class="content">
                    科大讯飞、蚂蚁数科、创维集团核心合作伙伴
                  </div>
                </div>
                <div class="in-slide">
                  <div class="time">2022</div>
                  <div class="line">
                    <div class="line-dy">
                      <div class="line-xy"></div>
                    </div>
                    <div class="x-line"></div>
                  </div>
                  <div class="content">安徽省创新型中小企业</div>
                  <div class="content">安徽省大数据企业</div>
                  <div class="content">合肥高新区瞪羚培育企业</div>
                  <div class="content">规模以上服务业企业</div>
                  <div class="content">合肥市企业软件收入上台阶</div>
                </div>
                <div class="in-slide">
                  <div class="time">2023</div>
                  <div class="line">
                    <div class="line-dy">
                      <div class="line-xy"></div>
                    </div>
                    <div class="x-line"></div>
                  </div>
                  <div class="content">安徽省专精特新中小企业</div>
                  <div class="content">讯飞AI服务市场优秀联台创新伙伴</div>
                  <div class="content">旷视行业方案合作伙伴</div>
                  <div class="content">宿州移动DICT项目战略合作伙伴</div>
                </div>
              </div>
            </div>
            <!-- 设置左右按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="about_box ryzs" id="scrollRyzs">
      <!-- <div class="cuboid">
        <div class="px"></div>
        <div class="cf"></div>
      </div> -->
      <div class="btop">
        <div class="title">荣誉证书</div>
      </div>
      <div class="box2">
        <div class="out-swiper-container2">
          <div
            class="swiper-container swiper-container2"
            id="imgSwiper2"
            ref="myimgSwiper"
          >
            <!-- <div class="Gtitle">合作伙伴</div> -->
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="../assets/sdImages/about/Slice1@2x.png" alt="" />
              </div>
              <div class="swiper-slide">
                <img src="../assets/sdImages/about/Slice2@2x.png" alt="" />
              </div>
              <div class="swiper-slide">
                <img src="../assets/sdImages/about/slice-3.jpg" alt="" />
              </div>
              <div class="swiper-slide">
                <img src="../assets/sdImages/about/slice-4.jpg" alt="" />
              </div>
              <div class="swiper-slide">
                <img src="../assets/sdImages/about/slice-5.jpg" alt="" />
              </div>
              <!-- <div class="swiper-slide">
                <div class="small-img">
                  <img src="../assets/sdImages/about/Group1613.png" alt="" />
                </div>
                <div class="text">国家高新技术企业</div>
              </div>
              <div class="swiper-slide">
                <div class="small-img">
                  <img src="../assets/sdImages/about/Group1614.png" alt="" />
                </div>
                <div class="text">软件企业证书</div>
              </div>
              <div class="swiper-slide">
                <div class="big-img">
                  <img src="../assets/sdImages/about/Group1615.png" alt="" />
                </div>
                <div class="text">信息技术服务管理体系认证证书</div>
              </div>
              <div class="swiper-slide">
                <div class="big-img">
                  <img src="../assets/sdImages/about/Group1616.png" alt="" />
                </div>
                <div class="text">企业信用等级证书AAA</div>
              </div>
              <div class="swiper-slide">
                <div class="big-img">
                  <img src="../assets/sdImages/about/Group1617.png" alt="" />
                </div>
                <div class="text">质量管理体系认证证书</div>
              </div> -->
            </div>
            <!-- 设置左右按钮 -->
            <div class="swiper-button-prev" id="button-prev"></div>
            <div class="swiper-button-next" id="button-next"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="about_box dlwz" id="scrollDlwz">
      <div class="btop">
        <div class="title">地理位置</div>
      </div>
      <div class="box2">
        <div class="imgss">
          <!-- <div class="name">合肥善达信息科技有限公司</div>
          <img src="../assets/sdImages/about/Group1595.png" alt="" /> -->
          <!-- <div class="bwbg"></div> -->
          <BMap
            ak="GEaNCmfcKktrWHd6uqylX9veE960yLom"
            :center="center"
            class="map"
            :scroll-wheel-zoom="true"
            :zoom="zoom"
            @ready="bMapLoad"
          />
        </div>
        <div class="adress">
          <div class="title">地址：</div>
          <div class="content">合肥市蜀山区安徽智能软件产业园一期1号楼3层</div>
        </div>
        <div class="two-box">
          <div class="adress">
            <div class="title">电话：</div>
            <div class="content">18955110833</div>
          </div>
          <div class="adress">
            <div class="title">邮箱：</div>
            <div class="content">sales@quickdone.cn</div>
          </div>
        </div>
      </div>
    </div>
    <mbottom />
    <demonstrate
      :dialogVisible="dialogVisible"
      @closeDialog="closeDialog"
    ></demonstrate>
  </div>
</template>

<script>
import { connectTron } from "@/api/Public";
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import { staClickMenu } from "../utils/index";
import demonstrate from "../components/demonstrate.vue";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import BMap from "vue-baidu-map/components/map/Map";
let mapConstructor = null; // 百度地图构造函数存放容器
export default {
  data() {
    return {
      dialogVisible: false,
      heightList: [],
      searchKey: "",
      center: {
        lng: 117.149425,
        lat: 31.873609,
      }, //lng代表纬度 lat代表经度
      zoom: 19,
      map: null, // 地图容器
      BMap: null, // 构造器容器
      firstIndex: 1,
      show: false,
      list: [],
      current2: 0,
      jqNewsBtns: [
        { id: "1", name: "公司介绍" },
        { id: "2", name: "发展历程" },
        { id: "3", name: "荣誉证书" },
        { id: "4", name: "地理位置" },
      ],
      someId1: null,
      someId2: null,
      someId3: null,
      someId4: null,
      distanceTop2: 0,
      distanceTop3: 0,
      distanceTop4: 0,
      videoSource: "",
      start: {},
      showControls: false,
    };
  },

  components: {
    mtop,
    mbottom,
    BMap,
    demonstrate,
  },

  mounted() {
    // 公司介绍
    this.someId1 = document.getElementById("scrollGsjs");
    // 发展历程
    this.someId2 = document.getElementById("scrollFzlc");
    // 荣誉证书
    this.someId3 = document.getElementById("scrollRyzs");
    // 地理位置
    this.someId4 = document.getElementById("scrollDlwz");

    this.distanceTop2 =
      this.someId2.getBoundingClientRect().top + window.pageYOffset - 200;
    this.distanceTop3 =
      this.someId3.getBoundingClientRect().top + window.pageYOffset - 200;
    this.distanceTop4 =
      this.someId4.getBoundingClientRect().top + window.pageYOffset - 200;
    window.addEventListener("scroll", this.handleScroll);
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
    this.initImgSwiper();
    this.initImgSwiper2();
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    "$store.getters.sid": function (val) {
      this.handleFiexdToolItem(val);
    },
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    "$route.query.current": function (val) {
      this.current2 = val;
      this.$nextTick(() => {
        this.getScroll();
      });
    },
    deep: true,
    immediate: true,
  },
  created() {
    this.current2 = this.$route.query.current;
    this.$nextTick(() => {
      this.getScroll();
    });
    this.getList();
  },
  methods: {
    // 统计菜单点击
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != "免费试用") {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName);
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    toggleControls() {
      this.showControls = true;
    },
    bMapLoad({ BMap, map }) {
      this.$nextTick(() => {
        // 百度地图容器全局化
        this.map = map;
        // 百度地图构造函数全局化
        mapConstructor = BMap;
        this.BMap = BMap;
        this.bMapPoint();
      });
    },
    bMapPoint() {
      // 清除地图上的覆盖物
      this.map.clearOverlays();
      // 对单个经纬度点进行标注
      const mPoint = new mapConstructor.Point(this.center.lng, this.center.lat);
      // 创建文字标注
      const label = new mapConstructor.Label(`合肥善达信息科技有限公司`, {
        position: this.center, // 文本绑定的点位位置
        offset: new mapConstructor.Size(-90, -60), // 文本位置移动
      });
      // 自定义文字样式
      label.setStyle({
        color: "black",
        fontSize: "14px",
        height: "40px",
        lineHeight: "40px",
        border: "1px solid #000",
        paddingLeft: "10px",
        paddingRight: "10px",
      });
      // 将标注添加到地图中
      this.map.addOverlay(label);
      // 自定义图标
      var myIcon = new mapConstructor.Icon(
        require("../assets/sdImages/about/Group1595.png"),
        new mapConstructor.Size(20, 20) // 图标的宽度和高度
      );
      myIcon.setImageSize(new mapConstructor.Size(20, 20));
      var marker = new mapConstructor.Marker(mPoint, {
        icon: myIcon,
      });
      this.map.addOverlay(marker);
      // 直接使用默认的信息框
      let info =
        "<div><div style='font-family: PingFang SC, PingFang SC;font-weight: 600;font-size: 16px;color: #000000;font-style: normal;text-transform: none;'>合肥善达信息科技有限公司</div><div id='dddi' style='text-align: right;margin-top: 12px;cursor: pointer;color:#3A89FE'>导航</div></div>";
      const infoWindow = new mapConstructor.InfoWindow(info);
      marker.addEventListener("click", function () {
        this.map.openInfoWindow(infoWindow, mPoint);
      });
      this.map.openInfoWindow(infoWindow, mPoint);
      let _this = this;
      setTimeout(() => {
        document.getElementById("dddi").addEventListener("click", function () {
          const { lat, lng } = _this.center;
          const navUrl = `https://api.map.baidu.com/marker?location=${lat},${lng}&title=合肥善达信息科技有限公司&content=我在这里&output=html`;
          window.open(navUrl, "_blank");
        });
      }, 1000);
      // 创建信息窗口
      //  const infoWindow = new mapConstructor.InfoWindow("合肥善达信息科技有限公司", {
      //   width: 200,
      //   height: 40,
      //   fontSize: "20px",
      //   enableMessage: true,
      // });

      // // 添加点击事件，点击标注点时打开信息窗口
      // this.map.addEventListener("click", (event) => {
      //   console.log('event---->',event)
      //   console.log('marker------->',marker)
      //   event.stopPropagation(); // 阻止事件冒泡
      //   marker.addEventListener("click", () => {
      //     this.map.openInfoWindow(infoWindow, mPoint);
      //   })
      // });

      // // 默认打开信息窗口
      // this.map.openInfoWindow(infoWindow, mPoint);
    },
    getScroll() {
      if (this.current2 == 0) {
        this.someId1.scrollIntoView({ behavior: "smooth" });
      } else if (this.current2 == 1) {
        this.someId2.scrollIntoView({ behavior: "smooth" });
      } else if (this.current2 == 2) {
        this.someId3.scrollIntoView({ behavior: "smooth" });
      } else {
        this.someId4.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleScroll() {
      //滚动头部背景变色
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //   console.log("scrollTop---->", scrollTop);

      if (scrollTop >= 620) {
        document.getElementById("top-btns").style =
          "z-index:999;position:fixed;top:0;background: #ffffff;border: 1px solid hsla(0, 0%, 100%, .2);box-shadow: 0 8px 32px rgba(28, 67, 125, .06);";
        if (scrollTop < this.distanceTop2) {
          this.current2 = 0;
        }
        if (scrollTop >= this.distanceTop2) {
          this.current2 = 1;
        }
        if (scrollTop >= this.distanceTop3) {
          this.current2 = 2;
        }
        if (scrollTop >= this.distanceTop4) {
          this.current2 = 3;
        }
      }

      if (scrollTop < 620) {
        document.getElementById("top-btns").style =
          "position:static;top:0;border: none;box-shadow: none;";
      }
    },
    initImgSwiper() {
      new Swiper("#imgSwiper", {
        loop: false, // 循环模式选项
        slidesPerView: 1, // 根据卡片的宽度自动调整每屏显示的卡片数量
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //导航器
        autoplay: false,
        // 其他配置项...
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    initImgSwiper2() {
      new Swiper("#imgSwiper2", {
        loop: false, // 循环模式选项
        slidesPerView: 1, // 根据卡片的宽度自动调整每屏显示的卡片数量
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //导航器
        autoplay: false,
        // 其他配置项...
        navigation: {
          nextEl: "#button-next",
          prevEl: "#button-prev",
        },
      });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getList() {
      this.$axios({
        method: "get",
        url: "v1/articleTypeList",
        params: { pid: 4 },
      })
        .then((res) => {
          this.list = res.data.data;
          console.log(this.list);
        })
        .catch(() => {
          console.log("请求失败");
        });
    },
    toPage(row) {
      // console.log(row);
      // this.$toast('敬请期待');
      // return
      sessionStorage.setItem("conid", row.id);
      this.$router.push({
        name: "recruiting",
        params: {
          id: row.id,
        },
      });
    },
    clickNav(index) {
      this.firstIndex = index;
      var navPage = document.querySelector(".right_box" + index);
      // const wrap=this.sysWrap.wrap;
      // wrap.scrollTop=navPage.offsetTop;
      window.scrollTo({
        top: navPage.offsetTop,
        behavior: "smooth",
      });
    },
    handler({ BMap, map }) {
      var point = new BMap.Point(111, 111);
      map.centerAndZoom(point, 19);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      var circle = new BMap.Circle(point, 1, {
        strokeColor: "Red",
        strokeWeight: 1,
        strokeOpacity: 1,
        Color: "Red",
        fillColor: "#f03",
      });
      map.addOverlay(circle);
    },
    getClickInfo(e) {
      console.log(e.point.lng);
      console.log(e.point.lat);
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
    },

    readDetail() {
      this.$router.replace({ path: "/help2" });
    },
    goHelpMore(i) {
      console.log(i);
      this.$router.replace({ path: "/help1?index=" + i });
    },
    wallet() {
      var i = 0;
      var a = setInterval(() => {
        if (typeof window.ethereum !== "undefined") {
          connectTron().then(
            (info) => {
              clearInterval(a);
              if (info[0].indexOf("0x") == 0) {
                if (info[0] != this.$store.getters.userWallet) {
                  this.buy = true;
                }
                this.$store.commit("userWallet", info[0]);
              } else {
                this.$toast("Use the ETH address to log in to the game");
              }
            },
            (e) => {
              this.$toast(e.message);
            }
          );
        }
        if (i > 5) {
          clearInterval(a);
        }
        i++;
      }, 1000);
    },

    tobuy() {
      location.href = "https://opensea.io/collection/cupid-love-metaverse";
    },
    up() {
      if (this.sqloading) {
        return;
      }
      this.buynum = Number(this.buynum) + 1;
    },
    dow() {
      if (this.sqloading) {
        return;
      }
      this.buynum = this.buynum <= 1 ? 1 : Number(this.buynum) - 1;
    },
    close() {
      this.shows = false;
    },
    handleFiexdToolItem(e) {
      if (!e) {
        return;
      }
      this.$nextTick(() => {
        document.getElementById(e).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
$t-mf: "Arial";

.main {
  background: #ffffff;
  min-height: 100vh;
  max-width: 100%;
  min-width: 1200px;
  width: 100%;

  .htop {
    height: 64px;

    #ts-top {
      position: relative;
    }
  }

  // .banner {
  // 	width: 100%;
  // 	font-size: 0;

  // 	.simg {
  // 		width: 100%;
  // 		height: 550px;
  // 	}
  // }

  .banner {
    max-width: 100%;
    min-width: 1200px;
    width: 100%;
    // margin-bottom: 19px;

    .banimg {
      max-width: 100%;
      min-width: 1200px;
      width: 100%;
      height: 550px;
      position: relative;
      text-align: left;
      background: url(~@/assets/sdImages/about/Maskgroup.png) no-repeat;
      // background-size: 100% 100%;
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        width: 1200px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 36px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
      }

      .tip {
        width: 1200px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
        margin-top: 13px;
      }

      .btn-box {
        width: 1200px;
        margin-top: 32px;

        .btn {
          width: 128px;
          height: 48px;
          background: #1664ff;
          cursor: pointer;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 20px;
          color: #ffffff;
          text-align: center;
          font-style: normal;
          text-transform: none;
          padding: 9px 0;
          box-sizing: border-box;
        }

        .btn:hover {
          opacity: 0.9;
        }
      }
    }
  }

  .abg-radio {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 77px;
    box-sizing: border-box;

    .abg-nav {
      // border: 1px solid #e8e9ea;
      height: 77px;
      margin-right: 23px;
      padding: 0 45px;
      font-family: PingFang SC, PingFang SC;
      line-height: 77px;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      font-style: normal;
      text-transform: none;
      cursor: pointer;
      box-sizing: border-box;
    }

    .active {
      background: #3a89fe;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      font-style: normal;
      text-transform: none;
      height: 77px;
      line-height: 77px;
    }
  }

  .some-line {
    width: 100%;
    border: 1px solid #8d8f94;
    opacity: 0.2;
    // margin-top: 26px;
  }

  .main_box {
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    // padding: 32px 28px;
    display: flex;
    flex-direction: column;
    text-align: left;
    cursor: default;

    .mbg {
      width: 100%;
      height: auto;
    }

    .box1 {
      display: flex;
      margin-top: 45px;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 1200px;
      margin: 0 auto;
      padding: 118px 0 100px 0;

      .img {
        img {
          width: 700px;
          height: 419px;
        }

        video {
          width: 700px;
          height: 419px;
        }
      }

      .left_con {
        display: flex;
        flex-direction: column;
        // margin-right: 63px;
        width: 435px;
        position: relative;

        .imgabout {
          width: 409px;
          height: 59px;
          position: absolute;
          top: 50px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .title {
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 36px;
          color: #1e1e1e;
          font-style: normal;
          text-transform: none;
          margin: 12px 0 79px 0;
          z-index: 9;
        }

        .cont {
          // width: 556px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #1e1e21;
          line-height: 30px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 23px;
        }
      }

      .video-box {
        position: relative;

        .play {
          width: 58px;
          height: 58px;
          position: absolute;
          top: 40%;
          left: 46%;
        }
      }

      .item {
        width: 358px;
        height: 140px;
        box-sizing: border-box;
        margin-top: 77px;
        display: flex;
        align-items: center;
        background: linear-gradient(224deg, #f3f5f8 24%, #ffffff 80%);
        box-shadow: inset 4px -2px 0px 0px #ffffff,
          inset -3px 3px 0px 0px rgba(255, 255, 255, 0.94),
          -1px 1px 21px 0px rgba(255, 255, 255, 0.9),
          0px 24px 48px 0px rgba(195, 203, 215, 0.14),
          27px 11px 24px 0px rgba(210, 218, 227, 0.2);
        border-radius: 8px 8px 8px 8px;
        transition: all 0.2s linear;

        .img {
          img {
            width: 86px;
            height: 86px;
          }
        }

        .cont {
          margin-left: 7px;

          .title {
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 20px;
            color: #000000;
            text-align: left;
            font-style: normal;
            text-transform: none;
            // margin-bottom: 12px;
          }

          .txt {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #404e66;
            font-style: normal;
            text-transform: none;
          }
        }
      }

      .item:hover {
        transform: scale(1.03);
        box-shadow: 27px 8px 24px rgba(210, 218, 227, 0.14),
          0 24px 48px rgba(195, 203, 215, 0.3),
          27px 11px 24px rgba(210, 218, 227, 0.24),
          inset -3px 3px 0 hsla(0, 0%, 100%, 0.94), inset 4px -2px 0 #fff;
      }
    }

    .box4 {
      display: flex;
      margin-top: 45px;
      justify-content: center;
      width: 1200px;
      margin: 80px auto;
      align-items: center;
      flex-direction: column;

      // background-color: #f5f6fa;
      .right_title {
        font-size: 28px;
        // font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .right_tip {
        font-size: 14px;
        // font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }

      .box_cont {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 36px;

        .img {
          width: 280px;
          height: 186px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #dcdee0;
          margin: 12px 0;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 250px;
            height: 166px;
          }
        }
      }
    }
  }

  .fzlc {
    width: 100%;
    height: 800px;
    background: url(~@/assets/sdImages/about/Group1577.png) no-repeat;
    background-size: 100% 100%;
    background-position: center center;
  }

  .ryzs {
    width: 100%;
    height: 703px;
    background: #f3f5fb;
    position: relative;

    .cuboid {
      position: absolute;
      bottom: 80px;
      width: 100%;

      .px {
        border-bottom: 50px solid #fff;
        /* 下边的梯度 */
        border-left: 25px solid transparent;
        /* 左边斜面的透明部分 */
        border-right: 25px solid transparent;
        /* 右边斜面的透明部分 */
        height: 0;
        /* 高度设置为0 */
        width: 96.5%;
        /* 宽度 */
        margin: 0 atuo;
      }

      .cf {
        width: 100%;
        height: 100px;
        background: linear-gradient(180deg, #f3f5fb 0%, #ffffff 100%);
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  .dlwz {
    width: 100%;
    height: 1080px;
    background: #ffffff;
  }

  .about_box {
    padding: 100px 0;
    box-sizing: border-box;

    .btop {
      width: 1200px;
      margin: 0 auto;

      .title {
        // font-size: 32px;
        // font-weight: 500;
        // color: #1D2129;
        // line-height: 45px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 36px;
        color: #1e1e1e;
        text-align: center;
        font-style: normal;
        text-transform: none;
        // margin-left: 39px;
      }

      .tips {
        // font-size: 16px;
        // font-weight: 400;
        // color: #646566;
        // line-height: 22px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
        line-height: 30px;
        font-style: normal;
        text-transform: none;
      }
    }

    .box2 {
      width: 1200px;
      margin: 0 auto;

      .imgss {
        width: 100%;
        height: 500px;
        // background: url(~@/assets/sdImages/about/image93.png) no-repeat;
        // background-size: 100% 100%;
        // background-position: center center;
        margin-top: 91px;
        position: relative;

        .map {
          width: 100%;
          height: 500px;
        }

        // 去掉百度地图左下角的logo
        :deep() {
          .BMap_cpyCtrl {
            display: none;
          }

          .anchorBL {
            display: none;
          }
        }

        // .bwbg {
        //   position: absolute;
        //   left: 638px;
        //   bottom: 141px;
        //   width: 25px;
        //   height: 25px;
        //   border-radius: 50%;
        //   background: rgba(58, 137, 254, 0.3);
        //   animation: wave-effect 2s infinite;
        // }

        img {
          width: 20px;
          height: 20px;
          position: absolute;
          left: 638px;
          bottom: 141px;
        }

        @keyframes wave-effect {
          0% {
            transform: translate(-10%, 5%) scale(1);
            opacity: 0;
          }

          20% {
            opacity: 1;
          }

          75% {
            transform: translate(-10%, 5%) scale(2);
            opacity: 0;
          }

          100% {
            opacity: 0;
          }
        }

        .name {
          position: absolute;
          left: 524px;
          bottom: 178px;
          width: 240px;
          height: 55px;
          font-family: Roboto, Roboto;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          line-height: 55px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          background: #ffffff;
          box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08),
            0px 16px 24px 2px rgba(0, 0, 0, 0.04),
            0px 6px 30px 5px rgba(0, 0, 0, 0.05);
          border-radius: 6px 6px 6px 6px;
          border: 1px solid #dcdcdc;
        }

        .name::before {
          content: "";
          position: absolute;
          top: 100%;
          right: 45%;
          // transform: translateX(50%);
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 10px solid #ddd;
        }

        .name:after {
          content: "";
          position: absolute;
          top: 100%;
          right: 45%;
          // transform: translateX(50%);
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 10px solid white;
        }
      }

      .adress {
        margin-top: 40px;
        margin-bottom: 20px;

        .title {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 20px;
          color: #1e1e1e;
          font-style: normal;
          text-transform: none;
        }

        .content {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 26px;
          color: #1e1e1e;
          font-style: normal;
          text-transform: none;
        }
      }

      .two-box {
        display: flex;
        justify-content: space-between;
      }

      .out-swiper-container {
        position: relative;
        margin-top: 117px;
      }

      .out-swiper-container2 {
        position: relative;
        margin-top: 76px;
      }

      .swiper-container2 {
        position: initial;

        .swiper-wrapper {
          height: auto;
        }

        .text {
          font-weight: 500;
          font-size: 16px;
          color: #000000;
          line-height: 27px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          margin-top: 33px;
        }

        .swiper-slide {
          // height: 340px;
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          // justify-content: end;
          img {
            width: 1200px;
            height: 340px;
          }

          .small-img {
            width: 190px;
            height: 190px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .big-img {
            width: 190px;
            height: 260px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        /* 自定义按钮样式 */
        .swiper-button-next,
        .swiper-button-prev {
          width: 42px;
          height: 42px;
          // background: url(~@/assets/sdImages/party/Group15.png) no-repeat;
          // background-size: 100% 100%;
          background-color: #3a89fe;
          border-radius: 100%;
          /* 设置背景颜色 */
          opacity: 1;
          color: #ffffff;
          z-index: 99;
        }

        .swiper-button-disabled {
          background-color: #c1daff;
        }

        .swiper-button-next::after {
          font-size: 15px;
        }

        .swiper-button-prev::after {
          font-size: 15px;
        }

        .swiper-button-prev {
          left: -80px;
        }

        .swiper-button-next {
          right: -80px;
        }

        /* 鼠标悬浮时改变样式 */
        .swiper-button-next:hover,
        .swiper-button-prev:hover {
          opacity: 1;
          /* 更改悬浮时的透明度 */
        }
      }

      .swiper-container1 {
        position: initial;

        /* 自定义按钮样式 */
        .swiper-button-next,
        .swiper-button-prev {
          width: 42px;
          height: 42px;
          // background: url(~@/assets/sdImages/party/Group15.png) no-repeat;
          // background-size: 100% 100%;
          background-color: #3a89fe;
          border-radius: 100%;
          /* 设置背景颜色 */
          opacity: 1;
          color: #ffffff;
          z-index: 99;
        }

        .swiper-button-disabled {
          background-color: #c1daff;
        }

        .swiper-button-next::after {
          font-size: 15px;
        }

        .swiper-button-prev::after {
          font-size: 15px;
        }

        .swiper-button-prev {
          top: 403px;
          left: 40%;
        }

        .swiper-button-next {
          top: 403px;
          right: 40%;
        }

        /* 鼠标悬浮时改变样式 */
        .swiper-button-next:hover,
        .swiper-button-prev:hover {
          opacity: 1;
          /* 更改悬浮时的透明度 */
        }
      }

      .swiper-container1 > .swiper-wrapper > .swiper-slide {
        // width: 309px !important;
        height: 333px;
        display: flex;
        .in-slide {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .time {
          width: 254px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 30px;
          color: #1e1e1e;
          font-style: normal;
          text-transform: none;
          margin-bottom: 20px;
          // margin-left: 20px;
          margin-top: 41px;
        }

        .line {
          display: flex;
          align-items: center;
          justify-content: center;
          // margin-left: 55px;
          margin-bottom: 20px;
          transform: translateX(20px);

          .line-dy {
            width: 22px;
            height: 22px;
            background: #cfe2ff;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .line-xy {
              width: 8px;
              height: 8px;
              background: #3a89fe;
              border-radius: 100%;
            }
          }

          .x-line {
            width: 280px;
            border: 1px dashed #cccccc;
          }
        }

        .content {
          width: 254px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #1e1e21;
          line-height: 30px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          position: relative;
          padding-left: 15px;
        }
        .content::before {
          content: "";
          width: 5px;
          height: 5px;
          position: absolute;
          left: 0px;
          top: 9px;
          background-color: #ffffff;
          border: 2px solid #3a89fe;
          border-radius: 100%;
        }
      }
      .in-slide:hover {
        background: #3a89fe;
        border-radius: 6px 6px 6px 6px;

        .time {
          color: #ffffff !important;
        }

        .content {
          color: #ffffff !important;
        }
        .content::before {
          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
          left: 0px;
          top: 9px;
          background-color: #ffffff;
          border-radius: 100%;
        }

        .line {
          .line-dy {
            background: #ffffff !important;
          }

          .x-line {
            // width: 280px;
            border: 1px dashed #ffffff !important;
          }
        }
      }

      // .swiper-container1 > .swiper-wrapper > .swiper-slide-active {
      //   background: #3a89fe;
      //   border-radius: 6px 6px 6px 6px;

      //   .time {
      //     color: #ffffff;
      //   }

      //   .content {
      //     color: #ffffff;
      //   }

      //   .line {
      //     .line-dy {
      //       background: #ffffff;
      //     }

      //     .x-line {
      //       width: 280px;
      //       border: 1px dashed #ffffff;
      //     }
      //   }
      // }

      .item {
        display: flex;
        align-items: flex-start;

        .img {
          img {
            width: 129px;
            height: 129px;
          }
        }

        .cont {
          margin-left: 54px;

          .title {
            font-size: 24px;
            // font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1d2129;
            line-height: 33px;
            text-align: left;
            margin-bottom: 24px;
          }

          .txt {
            font-size: 16px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #646566;
            text-align: left;
          }
        }
      }
    }
  }

  .box3 {
    margin-top: 60px;
    text-align: center;

    .right_title {
      font-size: 28px;
      // font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    .right_tip {
      font-size: 14px;
      // font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }

    .right_con {
      display: flex;
      margin-top: 35px;
      justify-content: center;

      img {
        width: 1200px;
      }
    }
  }

  .box6 {
    text-align: center;
    background: #f4f6fe;

    //  margin-top:50px;
    .right_title {
      margin-top: 50px;
      font-size: 28px;
      // font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    .right_tip {
      font-size: 14px;
      // font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-bottom: 40px;
      // line-height: 60px;
    }

    .box_cont {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 1226px;
      margin: 0 auto 80px;

      .item {
        width: 280px;
        height: 186px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dcdee0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        margin: 13px;

        .title {
          font-size: 18px;
          // font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 25px;
        }

        .btn {
          min-width: 122px;
          height: 40px;
          background: #ffffff;
          border-radius: 2px;
          font-size: 16px;
          // font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b6bff;
          line-height: 40px;
          margin-top: 20px;
          border: 1px solid #2b6bff;
          cursor: pointer;
        }
      }

      .item:hover {
        box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
      }
    }
  }
}

.h5 {
  min-width: 100%;

  .htop {
    height: 125px;
  }

  .banner {
    width: 100%;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .simg {
      width: 1000px;
      height: auto;
    }
  }

  .main_box {
    .box1 {
      width: 100%;
      flex-direction: column;
      align-items: center;
      padding: 32px 0;

      .left_con {
        margin: 0 37px;

        .title {
          font-size: 36px;
          text-align: center;
          font-weight: bold;
        }

        .cont {
          font-size: 26px;
          line-height: 42px;
          margin: 0 auto 40px;
          width: calc(100% - 64px);
        }
      }
    }

    .box4 {
      width: 100%;

      .right_title {
        font-size: 36px;
        font-weight: bold;
      }

      .right_tip {
        font-size: 26px;
        margin: 10px auto;
      }

      .box_cont {
        padding: 32px 28px;
        width: calc(100% - 56px);

        .img {
          width: calc(50% - 30px);
        }
      }
    }
  }

  .box3 {
    .right_title {
      font-size: 36px;
      font-weight: bold;
    }

    .right_tip {
      font-size: 26px;
      margin: 10px auto;
    }

    .right_con {
      padding: 32px 28px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .about_box {
    .box2 {
      width: 100%;
      flex-direction: column;

      .item {
        width: calc(100% - 60px);
        margin: 0 auto;
        align-items: center;

        .cont {
          .title {
            font-size: 32px;
            margin: 10px 0;
          }

          .txt {
            font-size: 26px;
            height: 36px;
          }
        }
      }
    }
  }

  .box6 {
    .box_cont {
      width: calc(100% - 56px);
      margin: 50px auto;

      .item {
        width: calc(50% - 26px);

        .title {
          font-size: 26px;
          line-height: 42px;
        }

        .btn {
          font-size: 24px;
        }
      }
    }
  }
}

@media screen and (min-width: 0px) {
  .Gtitle {
    text-align: center;

    h1 {
      font-size: 24px;
      font-weight: 500;
      color: #000000;
      line-height: 45px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: #969799;
      line-height: 22px;
    }
  }

  .enter_box {
    position: relative;
    padding: 30px 0;

    .fzbg {
      display: none;
      width: 100%;
    }

    ul {
      margin-top: 30px;

      li {
        margin-bottom: 40px;
        padding-left: 60px;
        position: relative;
        z-index: 1;

        &:last-child {
          &::before {
            display: none;
          }
        }

        &::after {
          content: "";
          width: 30px;
          height: 30px;
          border-radius: 50%;
          position: absolute;
          left: 10px;
          top: 0px;
          background-color: #f2f6fe;
          z-index: -1;
        }

        &::before {
          content: "";
          width: 1px;
          height: calc(100% + 20px);
          position: absolute;
          left: 25px;
          top: 0px;
          background-color: #2b6bff;
          z-index: -1;
        }

        h4 {
          font-size: 18px;
          font-weight: 500;
          color: #1d2129;

          &::after {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            left: 20px;
            top: 10px;
            background-color: #2b6bff;
          }
        }

        p {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #646566;
        }
      }
    }
  }
}

@media screen and (min-width: 760px) {
}

@media screen and (min-width: 820px) {
}

@media screen and (min-width: 1100px) {
  .Gtitle {
    h1 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
      margin-top: 14px;
    }
  }

  .enter_box {
    padding: 0;
    margin-top: 80px;

    .Gtitle {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }

    ul {
      display: none;
    }

    .fzbg {
      display: block;
    }
  }
}

@media screen and (min-width: 1300px) {
}
</style>
